const thoughts = [
    {
        id:1,
        thought:'“We are what we repeatedly do. Excellence, then, is not an act, but a habit.” – Aristotle'
    },
    {
        id:2,
        thought:'“If you’re offered a seat on a rocket ship, don’t ask what seat! Just get on.” – Sheryl Sandberg'
    },
    {
        id:3,
        thought:'“I always did something I was a little not ready to do. I think that’s how you grow. When there’s that moment of ‘Wow, I’m not really sure I can do this,’ and you push through those moments, that’s when you have a breakthrough.” – Marissa Mayer'
    },
    {
        id:4,
        thought:'“How wonderful it is that nobody need wait a single moment before starting to improve the world.” – Anne Frank'
    },
    {
        id:5,
        thought:'“Some people want it to happen, some wish it would happen, others make it happen.” – Michael Jordan'
    },
    {
        id:6,
        thought:'“You can either experience the pain of discipline or the pain of regret. The choice is yours.”  – Unknown'
    },
    {
        id:7,
        thought:'“If something is important enough, even if the odds are stacked against you, you should still do it.” – Elon Musk'
    },
    {
        id:8,
        thought:'“Don’t be afraid to give up the good to go for the great.” – John D. Rockefeller'
    },
    {
        id:9,
        thought:'“You’ve gotta dance like there’s nobody watching, love like you’ll never be hurt, sing like there’s nobody listening, and live like it’s heaven on earth.” ― William W. Purkey'
    },
    {
        id:10,
        thought:'“When one door of happiness closes, another opens; but often we look so long at the closed door that we do not see the one which has been opened for us.” ― Helen Keller'
    },
    {
        id:11,
        thought:'“All our dreams can come true, if we have the courage to pursue them.” – Walt Disney'
    },
    {
        id:12,
        thought:'“The secret of getting ahead is getting started.” – Mark Twain'
    },
    {
        id:13,
        thought:'“I’ve missed more than 9,000 shots in my career. I’ve lost almost 300 games. 26 times I’ve been trusted to take the game winning shot and missed. I’ve failed over and over and over again in my life and that is why I succeed.” – Michael Jordan'
    },
    {
        id:14,
        thought:'“Don’t limit yourself. Many people limit themselves to what they think they can do. You can go as far as your mind lets you. What you believe, remember, you can achieve.” – Mary Kay Ash'
    },
    {
        id:15,
        thought:'“The best time to plant a tree was 20 years ago. The second best time is now.” – Chinese Proverb'
    },
    {
        id:16,
        thought:'“It’s hard to beat a person who never gives up.” – Babe Ruth'
    },
    {
        id:17,
        thought:'“I wake up every morning and think to myself, ‘how far can I push this company in the next 24 hours.’” – Leah Busque'
    },
    {
        id:18,
        thought:'“We need to accept that we won’t always make the right decisions, that we’ll screw up royally sometimes – understanding that failure is not the opposite of success, it’s part of success.” – Arianna Huffington'
    },
    {
        id:19,
        thought:'“If you’re offered a seat on a rocket ship, don’t ask what seat! Just get on.” – Sheryl Sandberg'
    },
    {
        id:20,
        thought:'“Don’t limit your challenges. Challenge your limits.” – Unknown'
    },
    {
        id:21,
        thought:'“Everyone has inside them a piece of good news. The good news is you don’t know how great you can be! How much you can love! What you can accomplish! And what your potential is.” – Anne Frank'
    },
    {
        id:22,
        thought:'“Dreams don’t work unless you do.” – John C. Maxwell'
    },
    {
        id:23,
        thought:'H.O.P.E. = Hold On. Pain Ends.'
    },
    {
        id:24,
        thought:'“What defines us is how well we rise after falling.” – Lionel from Maid in Manhattan Movie'
    },
    {
        id:25,
        thought:'“Turn your wounds into wisdom” – Oprah'
    },
    {
        id:26,
        thought:'“Wherever you go, go with all your heart” – Confucius'
    },
    {
        id:27,
        thought:'“I hated every minute of training, but I said, ‘Don’t quit. Suffer now and live the rest of your life as a champion.” – Muhammad Ali'
    },
    {
        id:28,
        thought:'“Opportunities don’t happen. You create them.” – Chris Grosser'
    },
    {
        id:29,
        thought:'“Success is liking yourself, liking what you do, and liking how you do it.” – Maya Angelou'
    },
    {
        id:30,
        thought:'“Every champion was once a contender that didn’t give up.” – Gabby Douglas'
    },

    {
        id:31,
        thought:'Don’t dream about success. Get out there and work for it.'
    },
    {
        id:32,
        thought:'“When you reach the end of your rope, tie a knot and hang out.” – Abraham Lincoln'
    },{
        id:33,
        thought:'“I never lose. Either I win or learn.” – Nelson Mandela'
    },{
        id:34,
        thought:'“Today is your opportunity to build the tomorrow you want.” – Ken Poirot'
    },{
        id:35,
        thought:'“Focus on being productive instead of busy.” – Tim Ferriss'
    },{
        id:36,
        thought:'“Do the best you can. No one can do more than that.” – John Wooden'
    },
];

export default thoughts;