import React from 'react';

const Skills = () => {
    return (
        <div id='skills'>
        <div className='spacer-lg'></div>
            <div className='container-fluid'>
                <h1 className='head-sec'>Skills</h1>
            </div>
            <div className="row">
                
                <div className="col-lg-7 box">
                <h3 className='skill-head'>PROGRAMMING LANGUAGES & TOOLS</h3>
                <p className='skills'>C++, Python, Java
                <br/>Dart, Javascript
                <br/>Flutter, React
                <br/>Redux, Tensorflow</p>
                <h3 className='skill-head'>WORKFLOW</h3>
                    <ul >
                        <li className='about-me'>Cross-Platform App Development</li>
                        <li className='about-me'>Deep Learning Enthusiast</li>
                        <li className='about-me'>Web Developer</li>
                        <li className='about-me'>Software Development</li>
                        <li className='about-me'>UI/UX Developer</li>

                    </ul>
                </div>

                <div className="col-lg-5 skill-rot">
                    <div className='empty-container'></div>
                    <img src={`${process.env.PUBLIC_URL}/resources/skill.png`} alt="Muazzam Faraaz" className='skills-img' />
                    <div className='box-sq'>
                        <div className="outCircle">
                            <div className="rotate linear infinite duringFive">
                                <div className="counter">
                                    <div className="inner purple">
                                    </div>
                                </div>
                            </div>
                            <div className="second rotate linear infinite duringEight">
                                <div className="counter">
                                    <div className="inner-outer black">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='spacer-lg'></div>
        </div>
    );
};

export default Skills;