const internships=[
    {
        key:'1',
        role:"Technical Content Writer",
        at:"@GFG",
        duration:"January 2021 - July 2021",
        details:[
            "Working on Articles related to Flutter.",
            "Wrote articles on State Management with Flutter.",
            "Wrote articles on Animation with Flutter.",
            "Wrote Articles on Resposive Design with Flutter.",
            "Wrote article related to GUI in Python.",
            "Wrote Article on Threading in Java.",
            "Wrote Article on Dart Programming Language."
        ]
    },{
        key:'2',
        role:"UI/UX Developer",
        at:"@SpiderG",
        duration:"May - June 2020",
        details:[
            "Developed the Portal Page Design for the company with beautiful layout and simple structure.",
            "Designed the Logo, UI and UX for the Maharashtra Government Dutypar App.",
            "Worked on the Landing Page for the website.",
            "Designed the NFC feature for the App.",
            "Developed the domain specific page for the app."
        ]
    },
];


export default internships;
