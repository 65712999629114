import React from 'react';

const SocialMediaFloat = () => {
    return (
        <div className='icon-bar'>
            <a href="https://www.linkedin.com/in/muazzam-faraaz-b5562a167/" target="_blank"><i className="fab fa-linkedin-in"></i></a>
            <a href="https://github.com/mfaraaz" target="_blank"><i className="fab fa-github"></i></a>
            <a href="https://www.instagram.com/muazzamfaraaz/" target="_blank"><i className="fab fa-instagram"></i></a>
            <a href="https://www.behance.net/mfaraaz" target="_blank"><i className="fab fa-behance"></i></a>
            <i className="fas fa-angle-double-up up"></i>
        </div>
    );
};

export default SocialMediaFloat;