import React from 'react';
import Fade from 'react-reveal/Fade';
import internships from './internship_details';

const renderList = () => internships.map(intern => {
    return (
        <Fade bottom key={intern.key}>
            <div className='col-lg-6' >
                <div className='box-in intern-div'>
                    <img src={`${process.env.PUBLIC_URL}/resources/intern_logo${intern.key}.svg`} alt="company-logo" className='internship-logo' />
                    <br />
                    <p className='intern-as'>{intern.role} <span className='intern-comp'>{intern.at}</span></p>
                    <p className='intern-dur'>{intern.duration}</p>
                    <ul>{intern.details.map((detail) => {
                        return (
                            <li key={detail} className='intern-details'>{detail}</li>
                        );
                    })}
                    </ul>
                </div>
            </div>
        </Fade>

    );
});

const Experience = () => {


    return (
        <div id='internships'>
            <div className="spacer-lg"></div>
            <div className='container-fluid'>
                <h1 className='head-sec'>Internships</h1>
            </div>
            <div className="row" id='list'>
                {renderList()}
            </div>
            <div className="spacer-lg"></div>
        </div>
    );
};

export default Experience;