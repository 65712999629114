import React from 'react';
import './styles.css';

const Home = () => {
    return (
        <div>
            <div className='container-fluid'>
                <div className="row">
                    <div className="col-lg-8 head">
                        <h3 className='line1'>Hi, I'm</h3>
                        <h1 className='name'>Muazzam Faraaz</h1>
                        <h1 className='code'>come code with me.</h1>
                        <div className="col-lg-10">
                            <p className='intro'>I am an Engineering Student based in Chennai, I build things for the web
                            and mobile. I
                                develop (and sometimes design) things with beautiful Interface.</p>
                        </div>
                        <div className='spacer'></div>
                        <a href='mailto:muazzamfaraaz@gmail.com'><button type="button" className="btn btn-dark btn-lg">Get in Touch</button></a>
                    </div>

                    {/* Animation */}
                    <div className="col-lg-4">
                        <div className="outCircle">
                            <div className="rotate linear infinite duringFour">
                                <div className="counter">
                                    <div className="inner">
                                    </div>
                                </div>
                            </div>
                            <div className="second rotate linear infinite duringTen">
                                <div className="counter">
                                    <div className="inner-outer blue">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className='spacer-lg'></div>
        </div>

    )
};
export default Home;