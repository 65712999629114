import React from 'react';

const Footer=()=>{
    return (
        <div className='footer'>
            <h5>DENT THE UNIVERSE</h5>
            <p className='footnote'>dESIGNED in XD & Made with React <span className='heart'>♥</span> Muazzam</p>
            <p>© 2021 by Muazzam Faraaz</p>
        </div>
    );
}

export default Footer;