import React from 'react';

const About = () => {
    return (
        <div id='aboutme'>
        <div className='spacer-lg'></div>
            <div className='container-fluid'>
                <h1 className='head-sec'>About Me</h1>
            </div>
            <div className="row">
                <div className="col-lg-5">
                    <div className='empty-container'></div>
                    <img src={`${process.env.PUBLIC_URL}/resources/image_hov.png`} alt="Muazzam Faraaz" className='profile-img' />
                    <div className='box-sq'>
                        <div className="outCircle">
                            <div className="rotate linear infinite duringFour">
                                <div className="counter">
                                    <div className="inner blue">
                                    </div>
                                </div>
                            </div>
                            <div className="second rotate linear infinite duringTen">
                                <div className="counter">
                                    <div className="inner-outer purple">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-7 box">
                    <p className='about-me'>Hello! I'm Muazzam Faraaz, a penultimate student at SRMIST, Chennai.</p>

                    <p className='about-me'>I love to make things that is related to computers, whether that be a website,
                    application or
                        anything related. My goal is to always build product that provide a smooth user experience.</p>

                    <p className='about-me'>I am a tech enthusiast cause I explore new technology and what's behind them. I
                    Love Coding
                        keeping the code simple. Creativity helps me escape and wander in my thoughts.</p>

                    <p className='about-me'>Here are a few technologies I've been working with recently</p>

                    <ul>
                        <li>React</li>
                        <li>Flutter</li>
                        <li>Dart ...</li>

                    </ul>
                </div>
            </div>
            <div className='spacer-lg'></div>
        </div>
    );
};

export default About;