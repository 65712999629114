const projects=[
    {
        id:1,
        name:"Expenditurely",
        details:"Cross-platform app that helps us track our day to day expenditures easily and help us manage our expenses. It is linked to firebase.",
        link:"https://github.com/mfaraaz/expenditurely",
        button:"Github"
    },
    {
        id:2,
        name:"Hand Digit Recognition",
        details:"This uses MNIST handwritten dataset to classify the digits from 0-9. It uses 28*28px grey intensity images and labels the digits correctly.",
        link:"https://github.com/mfaraaz/DL",
        button:"Github"
    },
    {
        id:3,
        name:"billPRO",
        details:"Cross-platform app that helps us create invoices and generate its PDF in one tap. It keep tracks of user data and stores the information on the cloud with authentication.",
        link:"https://github.com/mfaraaz/bill_pro_v2",
        button:"Github"
    },
    {
        id:4,
        name:"Netflix Clone",
        details:"This is a simple Netflix UI Clone that is made using React and Redux for the frontend and uses firebase to authenticate the user on the backend.",
        link:"https://netflix-clone-2d9f6.web.app/",
        button:"Visit"
    },
    {
        id:5,
        name:"Path Finder",
        details:"This is a Simple Path Finder that uses an matrix to visualize the path using the A* Algorithm. More Path Finding Algorithms are coming soon.",
        link:"https://github.com/mfaraaz/path_finder",
        button:"Github"
    },{
        id:6,
        name:"BMI Calculator",
        details:"This is a simple BMI Calculator which is made in Flutter with a Simple and Beautiful UI. It works like a charm and finds your BMI Instantly.",
        link:"https://github.com/mfaraaz/BMI-Calculator",
        button:"Github"
    },
];


export default projects;