import React from 'react';
import './styles.css'
const Header = () => {
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark ">

                <div className="container-fluid navbar-container">
                    <a className="navbar-brand me-auto title" href="#"><img className="logo" src={`${process.env.PUBLIC_URL}/logo.svg`} alt="logo-img"
                        width="45" height="45" className="d-inline-block align-top" /></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item px-3">
                                <a className="nav-link hover-link" aria-current="page" href="#aboutme">About</a>
                            </li>
                            <li className="nav-item px-3">
                                <a className="nav-link hover-link" href="#internships">Experience</a>
                            </li>
                            <li className="nav-item px-3">
                                <a className="nav-link hover-link" aria-current="page" href="#skills">Skills</a>
                            </li>
                            <li className="nav-item px-3">
                                <a className="nav-link hover-link" aria-current="page" href="#projects">Projects</a>
                            </li>
                            <li className="nav-item px-3">
                                <a href='mailto:muazzamfaraaz@gmail.com' className="nav-link hover-link" aria-current="page">Contact</a>
                            </li>
                            <li className="nav-item px-3">
                                <a href={`${process.env.PUBLIC_URL}/resources/CV.pdf`} target="_blank"><button type="button" className="btn btn-dark">Resume</button></a>
                            </li>
                        </ul>
                    </div>
                </div>  
            </nav>
        </div>
    );
}

export default Header;