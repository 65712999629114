import React from 'react';
import thoughts from './thoughts';
import './styles.css';

const RandomThoughts=()=>{
    const id = Math.floor(Math.random() * 36); 
    return (
        <div>
        <div className='spacer-md'></div>
            <p className='thought'>{thoughts[id].thought}</p>
        <div className='spacer'></div>
        </div>
    );
};

export default RandomThoughts;