import React from 'react';
import Fade from 'react-reveal/Fade';
import projects from './project_list';

const renderList = () => projects.map(project => {
    return (
        <Fade bottom key={project.id}>
            <div >
                <div className='box-in-prj'>
                    <img src={`${process.env.PUBLIC_URL}/resources/prj-${project.id}.png`} alt="gfg" className='prj-image' />
                    <br />
                    <h5 className='prj-name'>{project.name}</h5>
                    <p className='prj-details'>{project.details}</p>
                    <a href={project.link} target="_blank"><button type="button" className="btn btn-dark" >{project.button}</button></a>
                </div>
            </div>
        </Fade>
    );
});

const Projects = () => {
    return (
        <div id='projects'>
            <div className="spacer-lg"></div>
            <div className='container-fluid'>
                <h1 className='head-sec'>Projects</h1>
            </div>
            <div className="row" id='prj-list'>
                {renderList()}
            </div>
            <div className="spacer-lg"></div>
        </div>
    );
};

export default Projects;