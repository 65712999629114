import React from 'react';
import Fade from 'react-reveal/Fade';

import SocialMediaFloat from './SocialMediaFloat';
import Header from './Header';
import Home from './Home';
import About from './About';
import './styles.css';
import Experience from './Experience';
import Skills from './Skills';
import Projects from './Projects';
import Footer from './Footer';
import RandomThoughts from './RandomThoughts';


const App = () => {
  return (
    <div>
      <SocialMediaFloat />
      <div className='container-fluid bg'>
        <Header />
        <div className='main-content'>
          <Fade bottom>
            <Home />
          </Fade>
          <Fade bottom>
          <Fade bottom>
            <RandomThoughts/>
          </Fade>
          <About />
          </Fade>
          <Fade bottom>

          <Experience />
          </Fade>
          <Fade bottom>
          <Skills />
          </Fade>
          <Fade bottom>
          <Projects />
          </Fade>
          
          
          
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
